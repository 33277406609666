/*import {useState} from 'react';
import {TwoFactorChallengePage} from '@common/auth/ui/two-factor/two-factor-challenge-page';
import {LoginPage} from '@common/auth/ui/login-page';

export function LoginPageWrapper() {
  const [isTwoFactor, setIsTwoFactor] = useState(false);
  if (isTwoFactor) {
    return <TwoFactorChallengePage />;
  } else {
    return <LoginPage onTwoFactorChallenge={() => setIsTwoFactor(true)} />;
  }
}*/


import { useEffect } from 'react';
export function LoginPageWrapper() {
  useEffect(() => {
    // Redirect immediately to the URL specified in the environment variable
    window.location.href = import.meta.env.VITE_BROKER_SERVER_URL+'/login?redirect=broker-sites-email';
  }, []);
  // Optionally, you can render null or a loader while the redirect happens
  return null;
}
